import i18n from '@/plugins/i18n'

export const STEP_TOPIC = {
  AI: 'syncAI',
}
export const API_STEP_KEY = {
  [STEP_TOPIC.AI]: {
    BRAIN_ID: 'brain-id',
    API_KEY: 'api-key',
  },
}

export const STEP_SUGGESTIONS = {
  [STEP_TOPIC.AI]: [
    {
      id: `${STEP_TOPIC.AI}-1`,
      key: `${API_STEP_KEY[STEP_TOPIC.AI].BRAIN_ID}`,

      title: i18n.tc('components.suggestionStep.syncAI.brainId.title'),
      subtitle: i18n.tc('components.suggestionStep.syncAI.brainId.subTitle'),
      path: '/settings/general',
      isCompleted: false,
    },
    {
      id: `${STEP_TOPIC.AI}-2`,
      key: `${API_STEP_KEY[STEP_TOPIC.AI].API_KEY}`,
      title: i18n.tc('components.suggestionStep.syncAI.apiKey.title'),
      subtitle: i18n.tc('components.suggestionStep.syncAI.apiKey.subTitle'),
      path: '/settings/general',
      isCompleted: false,
    },
  ],
}
