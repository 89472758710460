import Vue from 'vue'
import { xMan } from '@/utils'
import { httpClient } from '@/services/httpClient'
import { useEndpoints } from '@/composables/api/useEndpoints'
import { $acl } from '@/plugins/acl'

export default {
  namespaced: true,

  state: {
    // auth req status (idle/processing/succeeded/failed)
    authentication: 'idle',

    token: localStorage.getItem('token') || null,
    user: localStorage.getItem('user') || null,
    profile: localStorage.getItem('profile') || null,
    role: localStorage.getItem('role') || null,
    organization: localStorage.getItem('organization') || null,
    roles: localStorage.getItem('roles') || null,
    permissions: localStorage.getItem('permissions') || null,
    permissionActions: localStorage.getItem('permissionActions') || null,
  },
  getters: {
    // logged-in status
    status: (state) => {
      return state.token !== null ? true : false
    },
    // current auth req status
    reqStatus: (state) => {
      return state.authentication
    },
    userInfo: (state) => {
      let user = undefined
      try {
        user = JSON.parse(state.user)
      } catch (e) {
        console.log({ er: e })
        user = {}
      }
      return user
    },
    profileInfo: (state) => {
      let profile = undefined
      try {
        profile = JSON.parse(state.profile)
      } catch (e) {
        console.log({ er: e })
        profile = {}
      }
      return profile
    },
    roleInfo: (state) => {
      let role = undefined
      try {
        role = JSON.parse(state.role)
      } catch (e) {
        console.log({ er: e })
        role = {}
      }
      return role
    },
    rolesInfo: (state) => {
      let roles = undefined
      try {
        roles = JSON.parse(state.roles)
      } catch (e) {
        console.log({ er: e })
        roles = {}
      }
      return roles
    },
    organizationInfo: (state) => {
      let organization = undefined
      try {
        organization = JSON.parse(state.organization)
      } catch (e) {
        console.log({ er: e })
        organization = {}
      }
      return organization
    },
    accessToken: (state) => {
      return state.token
    },
    permissionsInfo: (state) => {
      let permissions = undefined
      try {
        permissions = JSON.parse(state.permissions)
      } catch (e) {
        console.log({ er: e })
        permissions = {}
      }
      return permissions
    },

    // getPermissionActions: (state) => {
    //   let permissionActions

    //   try {
    //     permissionActions = JSON.parse(
    //       localStorage.getItem('permissionActions')
    //     )
    //   } catch {
    //     permissionActions = state.permissionActions
    //   }

    //   return permissionActions
    // },
  },
  mutations: {
    AUTH_IDLE(state) {
      state.authentication = 'idle'
    },
    AUTH_FAILED(state) {
      state.authentication = 'failed'
    },
    AUTH_SUCCEEDED(state) {
      state.authentication = 'succeeded'
    },
    AUTH_PROCESSING(state) {
      state.authentication = 'processing'
    },
    AUTH_REMEMBER(state, payload) {
      state.token = payload.api_token
      state.user = JSON.stringify(payload.user)
      state.profile = JSON.stringify(payload.profile)
      state.role = JSON.stringify(payload.role)
      state.organization = JSON.stringify(payload.organization)
      state.roles = JSON.stringify(payload.roles)
      state.permissions = JSON.stringify(payload.permissions)
      // todo: remove logs
      console.log({ rem: state.user })
      // Save to localStorage
      localStorage.setItem('token', payload.api_token)
      localStorage.setItem('user', JSON.stringify(payload.user))
      localStorage.setItem('profile', JSON.stringify(payload.profile))
      localStorage.setItem('role', JSON.stringify(payload.role))
      localStorage.setItem('organization', JSON.stringify(payload.organization))
      localStorage.setItem('roles', JSON.stringify(payload.roles))
      localStorage.setItem('permissions', JSON.stringify(payload.permissions))
      // Set token to axios as well
      Vue.prototype.$http.defaults.headers.common[
        'Authorization'
      ] = `Token ${payload.api_token}`
      // Set user info
      Vue.prototype.$user = payload.user
      Vue.prototype.$permissions = payload.permissions
      Vue.prototype.$roles = payload.roles
      // console.log(Vue.prototype.$user)
    },
    AUTH_FORGET(state) {
      state.token = null
      state.user = null
      state.profile = null
      state.role = null
      state.organization = null
      state.permissionActions = null
      // Remove from localStorage
      localStorage.removeItem('token')
      localStorage.removeItem('user')
      localStorage.removeItem('profile')
      localStorage.removeItem('role')
      localStorage.removeItem('roles')
      localStorage.removeItem('organization')
      localStorage.removeItem('permissionActions')
      // Remove token from axios as well
      Vue.prototype.$http.defaults.headers.common['Authorization'] = null
      // Remove user info
      Vue.prototype.$user = {}
      Vue.prototype.$permissions = {}
      Vue.prototype.$roles = {}
    },

    setPermissionActions(state, payload) {
      state.permissionActions = payload
      localStorage.setItem('permissionActions', JSON.stringify(payload))
    },
  },

  actions: {
    async login({ commit, dispatch, getters }, payload) {
      let data = new xMan(payload).toFormData()
      // console.log('data = ', data)
      try {
        commit('AUTH_PROCESSING')
        let response = await httpClient.post(useEndpoints.auth.login(), data)
        console.log({ loginRes: response.data })

        let mutateRole, mutatePermissions
        if (
          response.data.user.is_superadmin &&
          response.data.user.organization_role === 'Owner'
        ) {
          mutateRole = response.data.role
          mutateRole.permissions.push({
            id: 101,
            name: 'Organizations',
            action_types: ['VIEW', 'CREATE', 'UPDATE', 'DELETE'],
          })

          mutatePermissions = response.data.permissions
          mutatePermissions.push({
            id: 101,
            name: 'Organizations',
            action_types: ['VIEW', 'CREATE', 'UPDATE', 'DELETE'],
          })
        } else if (
          !response.data.user.is_superadmin &&
          response.data.user.organization_role === 'Owner'
        ) {
          mutateRole = response.data.role
          mutateRole.permissions.push({
            id: 101,
            name: 'Organizations',
            action_types: ['VIEW', 'UPDATE'],
          })

          mutatePermissions = response.data.permissions
          mutatePermissions.push({
            id: 101,
            name: 'Organizations',
            action_types: ['VIEW', 'UPDATE'],
          })
        } else {
          mutateRole = response.data.role
          mutatePermissions = response.data.permissions
        }
        const resData = {
          api_token: response.data.api_token,
          organization: response.data.organization,
          profile: response.data.profile,
          role: mutateRole,
          user: response.data.user,
          roles: response.data.roles,
          permissions: mutatePermissions,
        }
        commit('AUTH_SUCCEEDED')
        commit('AUTH_REMEMBER', resData)

        let permissions = $acl
          .filterByAction('VIEW', getters.roleInfo?.permissions)
          .map((perm) => perm.name)
        dispatch('navigation/setMenuPayloadForPerms', permissions, {
          root: true,
        })
        console.log('perms', permissions)

        // // set permissions
        // const permissions = JSON.parse(state.role).permissions.map(
        //   (permission) => {
        //     return permission.name
        //   }
        // )
        // console.log('USER PERMS: ', permissions)

        // await dispatch('setPermissionActions').then(() => {
        //   console.log('Permissions actions fetched')
        //   const permissions = $acl
        //     .filterByAction('VIEW', getters.getPermissionActions)
        //     .map((perm) => perm.permission_name)

        //   dispatch('navigation/setMenuPayloadForPerms', permissions, {
        //     root: true,
        //   })
        //   console.log('perms', permissions)
        // })

        return response
      } catch (error) {
        console.log(error)
        commit('AUTH_FAILED')
        throw new Error(error.status)
      }
    },

    async logout({ commit }) {
      try {
        commit('AUTH_FORGET')
        return Promise.resolve(true)
      } catch (error) {
        throw new Error(error)
      }
    },

    async activate({ commit }, payload) {
      let data = new xMan(payload).toFormData()
      try {
        commit('AUTH_PROCESSING')
        let response = await httpClient.post(useEndpoints.auth.activate(), data)
        console.log({ re: response.data })
        commit('AUTH_SUCCEEDED')
        commit('AUTH_FORGET')

        let mutateRole, mutatePermissions
        if (
          response.data.user.is_superadmin &&
          response.data.user.organization_role === 'Owner'
        ) {
          mutateRole = response.data.role
          mutateRole.permissions.push({
            id: 101,
            name: 'Organizations',
            action_types: ['VIEW', 'CREATE', 'UPDATE', 'DELETE'],
          })

          mutatePermissions.push({
            id: 101,
            name: 'Organizations',
            action_types: ['VIEW', 'CREATE', 'UPDATE', 'DELETE'],
          })
        } else if (
          !response.data.user.is_superadmin &&
          response.data.user.organization_role === 'Owner'
        ) {
          mutateRole = response.data.role
          mutateRole.permissions.push({
            id: 101,
            name: 'Organizations',
            action_types: ['VIEW', 'UPDATE'],
          })
          mutatePermissions.push({
            id: 101,
            name: 'Organizations',
            action_types: ['VIEW', 'UPDATE'],
          })
        } else {
          mutateRole = response.data.role
          mutatePermissions = response.data.permissions
        }
        const resData = {
          api_token: response.data.api_token,
          organization: response.data.organization,
          profile: response.data.profile,
          role: mutateRole,
          user: response.data.user,
          roles: response.data.roles,
          permissions: mutatePermissions,
        }
        commit('AUTH_REMEMBER', resData)
        return response.status
      } catch (error) {
        throw new Error(error)
      }
    },

    async updatePassword({ commit }, payload) {
      const formData = new FormData()
      formData.append('old_password', payload.old_password)
      formData.append('new_password', payload.new_password)
      formData.append('confirm_new_password', payload.confirm_new_password)
      try {
        commit('AUTH_PROCESSING')
        let response = await httpClient.post(
          useEndpoints.user.org.changePassword(payload.userId),
          formData
        )
        // console.log({ pass_res: response.data });
        commit('AUTH_SUCCEEDED')
        return response.status
      } catch (error) {
        commit('AUTH_FAILED')
        console.log(error.response)
        throw new Error(error)
      }
    },

    // async setPermissionActions({ commit }) {
    //   return await httpClient
    //     .get(useEndpoints.auth.permissionActions())
    //     .then((res) => {
    //       commit('setPermissionActions', res.data.data)
    //     })
    //     .catch((err) => {
    //       console.log(err)
    //     })
    // },
  },
}
