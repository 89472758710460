import { API_STEP_KEY, STEP_TOPIC } from '@/components/suggestive-step/helper'
import { httpClient } from '@/services'

export default {
  namespaced: true,

  state: {
    isPopupShow: false,
    isFullView: false,
    isLoading: false,
    stepTopic: null,
    orgId: null,
    suggestions: [],
  },
  getters: {
    getPopViewStatus(state) {
      return state.isPopupShow
    },
    getFullViewStatus(state) {
      return state.isFullView
    },
    getReqStatus(state) {
      return state.isLoading
    },
    getPopupShopStatus: (state) => {
      return state.isPopupShow
    },
    getStepTopic: (state) => {
      return state.stepTopic
    },
    getCompletedSuggestions: (state) => {
      return state.suggestions.filter((step) => step.isCompleted === true)
    },
    getPendingSuggestion: (state) => {
      return state.suggestions.filter((step) => step.isCompleted === false)
    },
    getSuggestions(state) {
      return state.suggestions
    },
  },
  mutations: {
    SET_POPUP_VIEW_STATUS(state, value) {
      state.isPopupShow = value
    },
    SET_FULL_VIEW_STATUS(state, value) {
      state.isFullView = value
    },
    SET_STEP_TOPIC(state, value) {
      state.stepTopic = value
    },
    SET_ORG_ID(state, value) {
      state.orgId = value
    },
    SET_SUGGESTIONS(state, value) {
      state.suggestions = value
    },
    SET_REQ_STATUS(state, value) {
      state.isLoading = value
    },
  },
  actions: {
    async fetchData({ commit, state }) {
      if (state.stepTopic === STEP_TOPIC.AI) {
        commit('SET_REQ_STATUS', true)
        await httpClient
          .get(`/dashboard/organizations/${state.orgId}/general-settings/`)
          .then((res) => {
            const suggestions = state.suggestions.map((step) => {
              if (step.key === API_STEP_KEY[STEP_TOPIC.AI].BRAIN_ID) {
                return { ...step, isCompleted: !!res.data.chatbot_id }
              }
              if (step.key === API_STEP_KEY[STEP_TOPIC.AI].API_KEY) {
                return { ...step, isCompleted: !!res.data.magnusai_api_key }
              }
            })
            commit('SET_SUGGESTIONS', suggestions)
          })
          .finally(() => {
            commit('SET_REQ_STATUS', false)
            commit('SET_FULL_VIEW_STATUS', true)
          })
      }
    },
    async havePendingStepExist({ dispatch, getters }) {
      return new Promise((resolve, reject) => {
        dispatch('fetchData')
          .then(() => {
            resolve({ isExist: getters.getPendingSuggestion.length > 0 })
          })
          .catch((err) => reject({ err }))
      })
    },
  },
}
